import React, { Component } from 'react';
import { connect } from 'react-redux';

import { BASE_ZINDEX } from '../popup-factory';

import { getClientOptions } from '../selectors/dropdowns';

import { closePopup, createSpinnerPopup } from '../actions/popup';
import { createLoadClientList } from '../actions/client';
import { createJoinOrders, loadShopOrders } from '../actions/shop';

import Form from './Form';
import SpinnerPopup from './SpinnerPopup';

class JoinOrdersPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      job_name: '',
      loaded: !!props.clients.length,
      client_id: props.client_id
    };

    this.onFieldChange = this.onFieldChange.bind(this);
    this.onCreateProject = this.onCreateProject.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!this.state.loaded) {
      this.props.onLoadClients();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clients.length) {
      this.setState({ loaded: true });
    }
  }

  onFieldChange(value, field) {
    switch (field) {
      case 'job_name':
        this.setState({ job_name: value });
        break;
      case 'client_id':
        this.setState({ client_id: value });
        break;
    }
  }

  onCreateProject(values, errors) {
    const { onJoinOrders } = this.props;
    if (!Object.values(errors).every(e => e === null)) {
      return;
    }
    onJoinOrders(values.job_name, values.client_id ? values.client_id : this.state.client_id);
  }

  renderLoading() {
    return <SpinnerPopup index={this.props.index} />;
  }

  render() {
    const { clients, index, onClosePopup } = this.props;
    const { job_name, client_id, loaded } = this.state;

    if (!loaded) {
      return this.renderLoading();
    }

    const handleClickCreateProject = e => {
      e.preventDefault();
      this._form.submit();
    };

    return (
      <div className="reveal small" style={{ display: 'block', zIndex: BASE_ZINDEX + index, height: 'initial', overflow: 'visible' }}>
        <div className="row small-12 columns">
          <h3>Create Project</h3>
          <a className="alert button" style={{ position: 'fixed', right: '6rem', top: '1rem' }} onClick={onClosePopup}>Cancel</a>
          <a className="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={handleClickCreateProject}>Create</a>
        </div>
        <Form
          className="row small-12 columns form"
          ref={ref => this._form = ref}
          onFieldChange={this.onFieldChange}
          onSubmit={this.onCreateProject}
        >
          <Form.TextInput field="job_name" containerClassName="row collapse" labelClassName="small-12 columns" controlClassName="small-12 columns" required={true} label="Project Name" value={job_name} placeholder="New Project Name" />
          {/* <Form.Select field="client_id" label="Client" displayLabel={false} value={client_id} containerClassName="row collapse" labelClassName="small-1 columns" controlClassName="small-11 columns" options={clients} placeholder="Select Client" searchable={true} required={true} /> */}
        </Form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  clients: getClientOptions(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClosePopup: () => dispatch(closePopup()),
  onJoinOrders: (job_name, client_id) => {
    //dispatch(createSpinnerPopup('Creating project...'));
    dispatch(createJoinOrders(ownProps.shop_id, job_name, client_id, ownProps.order_ids));
  },
  onLoadClients: () => dispatch(createLoadClientList())
});

const ConnectedJoinOrdersPopup = connect(mapStateToProps, mapDispatchToProps)(JoinOrdersPopup);
export default ConnectedJoinOrdersPopup;
