import { fontFamilies, Loading } from '@commonsku/styles';
import React, { Component } from 'react';
import { BASE_ZINDEX } from '../popup-factory';

class SpinnerPopup extends Component {
  render() {
    const { index, useV2 = false, message = 'Loading...' } = this.props;
    return (
      <div className="reveal small" style={{ display: 'block', height: 'initial', zIndex: BASE_ZINDEX + index }}>
        <div className="row">
          <div className={`${useV2 ?  'small-12' : 'small-4'} columns`}>
            {useV2 ? <Loading /> : <img alt='Loading' src="/images/gears.gif" />}
          </div>
          <div
            className={`${useV2 ?  'small-12' : 'small-8'} columns`}
            style={useV2 ? {
              paddingTop: 16,
              fontSize: 16,
              fontFamily: fontFamilies.regular,
              textAlign: 'center',
            } : {}}
          >{message}</div>
        </div>
      </div>
    );
  }
}

export default SpinnerPopup;
